<template>
  <div class="w-full h-full p-5">
    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else class="w-full h-full">
      <div class="flex">
        <h1 class="text-xl text-left font-extrabold mr-8">Dashboard</h1>
      </div>
      <card class="w-full p-5 mt-4">
        <profile-card app-page="ESS" />

        <div class="flex w-full">
          <div class="w-full flex flex-wrap">
            <div
              class="mb-7"
              style="width: 49%; margin-right: 1%; height: 530px;"
            >
              <card class="w-full h-full p-2">
                <div class="w-full h-full p-3 bg-ghostWhite rounded-sm">
                <div class="w-full mt-2 mb-5 flex">
                  <div class="flex flex-grow text-base ml-3 font-semibold">
                    My Approvals (
                    <div class="text-xs uppercase text-flame font-semibold mt-1">
                      YTD
                    </div>
                    )
                  </div>
                  <div class="flex">
                    <span class="text-xs font-semibold mr-3 mt-1 uppercase">
                      {{ requestTab }}
                    </span>
                    <span class="">
                      <Menu left top="-20" margin="14" class="p-0 mt">
                        <template v-slot:title>
                          <Icon
                            icon-name="icon-arrow-down"
                            style="color: #F15A29"
                          />
                        </template>
                        <div
                          style="width: 260px; height: 200px"
                          class="py-3 px-2"
                        >
                          <div
                            style="padding: 0px 10px 15px; height: 30px"
                            class="text-center"
                          >
                            <span class="text-sm text-romanSilver">
                              --Select an option--
                            </span>
                          </div>
                          <div
                            class="border w-full mb-2"
                            style="height: 0px;"
                          />
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              requestTab === 'All' ? 'bg-flame text-white' : ''
                            "
                            @click="setRequestTab('All')"
                          >
                            <p class="pt-1">All</p>
                          </div>
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              requestTab === 'Pending'
                                ? 'bg-flame text-white'
                                : ''
                            "
                            @click="setRequestTab('Pending')"
                          >
                            <p class="pt-1">Pending</p>
                          </div>
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              requestTab === 'Active'
                                ? 'bg-flame text-white'
                                : ''
                            "
                            @click="setRequestTab('Active')"
                          >
                            <p class="pt-1">Active</p>
                          </div>
                        </div>
                      </Menu>
                    </span>
                  </div>
                </div>
                <div class="my-1 flex" />
                <div style="height:100%" v-if="approvalLoader">
                  <loader size="xxs" class="-mt-16" :loader-image="false" />
                </div>
                <div v-else>
                  <div
                    v-if="approvalsData.length === 0"
                    class="flex flex-col text-center mb-6"
                  >
                    <Icon
                      icon-name="dashboard-error"
                      class="-mt-1 iconSize mx-24"
                      style="width: 350px; height: 350px"
                    />
                    <p class="text-xl font-bold">
                      No Data Available
                    </p>
                    <p class="text-xs text-romanSilver">
                      There is no data to show currently.
                    </p>
                  </div>

                  <div v-else class="w-full flex flex-col">
                    <scroll-container height="400px">
                      <div class="flex flex-grow flex-col">
                        <div
                          v-for="(item, index) in approvalsData"
                          :key="index"
                        >
                          <ApproversCard
                            class="flex pt-1 px-3 pb-4 mb-2 even:bg-ghostWhite border-l-4"
                            :style="approveDetails(item.currentApprover)"
                          >
                            <div class="flex w-full">
                              <div class="flex w-auto pr-1 py-2 inline-block">
                                <img
                                  v-if="item.photo != null && item.photo != ''"
                                  class="rounded mr-3"
                                  alt="photo"
                                  style="width: 36px; height: 36px"
                                  :src="item.photo"
                                />
                                <div
                                  style="height: 36px; width: 36px; border-radius: 5px;"
                                  class="text-blueCrayola border text-center font-semibold pt-2 mr-3"
                                  v-else
                                >
                                  {{
                                    $getInitials(`${item.fname} ${item.lname}`)
                                  }}
                                </div>
                              </div>
                              <div class="flex flex-col w-3/4 mt-2 ml-2">
                                <div class=" leading-tight">
                                  <span class="block text-lg  leading-tight ">
                                    <span class="block text-base"
                                      >{{ item.fname }} {{ item.lname }}</span
                                    >
                                    <span
                                      class="text-romanSilver uppercase text-xs block"
                                    >
                                      {{ item.designation }}
                                    </span>
                                    <span class="block text-xs">{{
                                      $DATEFORMAT(
                                        new Date(item.dateSubmitted),
                                        "MMMM dd, yyyy"
                                      )
                                    }}</span>
                                  </span>
                                </div>
                                <div class=" flex w-full mt-1 leading-tight">
                                  <div class=" w-full">
                                    <span
                                      class="block text-lg flex leading-tight "
                                    >
                                      <span
                                        class="block text-base text-romanSilver"
                                        >Request:</span
                                      >
                                      <span
                                        class="ml-2 text-sm block"
                                        style="margin-top: 0.13rem"
                                      >
                                        {{ item.requestName }}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="flex-none w-4/12 text-base ">
                                <Tag
                                  class="py-2 px-2 mx-2 float-right mt-5 text-sm cursor-pointer"
                                  :class="approveBgColor(item.currentApprover)"
                                >
                                  <span
                                    @click="
                                      viewDetailedApproval(
                                        item.requestId,
                                        item.requestSlug
                                      )
                                    "
                                  >
                                    {{ approveText(item.currentApprover) }}
                                  </span>
                                </Tag>
                              </div>
                            </div>
                          </ApproversCard>
                        </div>
                      </div>
                    </scroll-container>
                  </div>
                </div>
                </div>
              </card>
            </div>

            <div
              class="mb-7"
              style="width: 49%; margin-left: 1%; height: 530px"
            >
              <card class="w-full h-full p-2">
                <div class="w-full h-full p-3 bg-ghostWhite rounded-sm">
                <div class="w-full mt-2 mb-5 flex">
                  <div class="flex flex-grow text-base ml-3 font-semibold">
                    Employee Movements (
                    <div class="text-xs uppercase text-flame font-semibold mt-1">
                      YTD
                    </div>
                    )
                  </div>
                  <div class="flex">
                    <span class="text-xs font-semibold mr-3 mt-1 uppercase">
                      {{ movementTab }}
                    </span>
                    <span style="margin-top: 5%">
                      <Menu left top="-20" margin="14" class="p-0">
                        <template v-slot:title>
                          <Icon
                            icon-name="icon-arrow-down"
                            style="color: #f15a29"
                          />
                        </template>
                        <div
                          style="width: 260px; height: 200px"
                          class="py-3 px-2"
                        >
                          <div
                            style="padding: 0px 10px 15px; height: 30px"
                            class="text-center"
                          >
                            <span class="text-sm text-romanSilver">
                              --Select an option--
                            </span>
                          </div>
                          <div class="border w-full mb-2" style="height: 0px" />
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              movementTab === 'Promotions'
                                ? 'bg-flame text-white'
                                : ''
                            "
                            @click="setMovementTab('Promotions')"
                          >
                            <p class="pt-1">Promotions</p>
                          </div>
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              movementTab === 'Transfers'
                                ? 'bg-flame text-white'
                                : ''
                            "
                            @click="setMovementTab('Transfers')"
                          >
                            <p class="pt-1">Transfers</p>
                          </div>
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              movementTab === 'Disengagements'
                                ? 'bg-flame text-white'
                                : ''
                            "
                            @click="setMovementTab('Disengagements')"
                          >
                            <p class="pt-1">Disengagements</p>
                          </div>
                        </div>
                      </Menu>
                    </span>
                  </div>
                </div>
                <div style="height: 100%" v-if="movementLoader">
                  <loader
                    size="xxs"
                    class="-mt-16 mb-10"
                    :loader-image="false"
                  />
                </div>
                <div v-else class="flex flex-col">
                  <div
                    v-if="movementLabels.length === 0"
                    class="flex flex-col text-center"
                  >
                    <Icon
                      icon-name="dashboard-error"
                      class="-mt-1 iconSize mx-24"
                      style="width: 350px; height: 350px"
                    />
                    <p class="text-xl font-bold">
                      No Data Available
                    </p>
                    <p class="text-xs text-romanSilver">
                      There is no data to show currently.
                    </p>
                  </div>
                  <div v-else>
                    <div class="mb-5 flex">
                      <span class="text-base flex flex-grow ml-3">
                        Current Movement: {{ currentMovement }}
                      </span>
                      <span class="text-base mr-2 font-semibold">
                        {{ movementGrowth === null ? "0" : movementGrowth }}%
                      </span>
                      <Icon
                        icon-name="chart-vector"
                        class="-mt-1 xsm"
                        style="color: #13B56A"
                      />
                    </div>
                    <LineChart
                      :data="movementData"
                      :options="movementOptions"
                      :styles="lineStyles"
                      :value="false"
                    />
                    <!----<div class="mt-4 w-full text-center">
                      <span class="text-blueCrayola text-base">
                        View Details
                      </span>
                    </div> -->
                  </div>
                </div>
                </div>
              </card>
            </div>


            <div
              class="mb-7"
              style="width: 49%; margin-right: 1%; height: 530px"
            >
              <card class="w-full h-full p-2">
                <div class="w-full h-full p-3 bg-ghostWhite rounded-sm">
                <div class="w-full mt-2 flex">
                  <div
                    class="flex flex-grow text-base ml-3 font-semibold"
                  >
                    <span class="text-base font-semibold">
                      Birthdays
                    </span>
                    <div class="text-sm uppercase text-flame font-semibold flex flex-col justify-center mx-1">
                      ({{ birthdayData.length }})
                    </div>
                  </div>
                  <div class="flex">
                    <span class="text-xs font-semibold mr-3 mt-1 uppercase">
                      {{ birthdayTab }}
                    </span>
                    <span class="">
                      <Menu left top="-20" margin="14" class="p-0 mt">
                        <template v-slot:title>
                          <Icon
                            icon-name="icon-arrow-down"
                            style="color: #f15a29"
                          />
                        </template>
                        <div
                          style="width: 260px; height: 200px"
                          class="py-3 px-2"
                        >
                          <div
                            style="padding: 0px 10px 15px; height: 30px"
                            class="text-center"
                          >
                            <span class="text-sm text-romanSilver">
                              --Select an option--
                            </span>
                          </div>
                          <div class="border w-full mb-2" style="height: 0px" />
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              birthdayTab === 'Today'
                                ? 'bg-flame text-white'
                                : ''
                            "
                            @click="setBirthdayTab('Today')"
                          >
                            <p class="pt-1">Today</p>
                          </div>
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              birthdayTab === 'This Week'
                                ? 'bg-flame text-white'
                                : ''
                            "
                            @click="setBirthdayTab('This Week')"
                          >
                            <p class="pt-1">This Week</p>
                          </div>
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              birthdayTab === 'This Month'
                                ? 'bg-flame text-white'
                                : ''
                            "
                            @click="setBirthdayTab('This Month')"
                          >
                            <p class="pt-1">This Month</p>
                          </div>
                        </div>
                      </Menu>
                    </span>
                  </div>
                </div>
                <div style="height: 100%" v-if="birthdayLoader">
                  <loader
                    size="xxs"
                    class="mt-10 mb-10"
                    :loader-image="false"
                  />
                </div>
                <div v-else>
                  <div
                    v-if="birthdayData.length === 0"
                    class="flex flex-col text-center mb-6"
                  >
                    <Icon
                      icon-name="dashboard-error"
                      class="-mt-1 iconSize mx-24"
                      style="width: 350px; height: 350px"
                    />
                    <p class="text-xl font-bold">
                      No Data Available
                    </p>
                    <p class="text-xs text-romanSilver">
                      There is no data to show currently.
                    </p>
                  </div>
                  <BirthdayCard :birthdays="birthdayData" v-else />
                </div>
                </div>
              </card>
            </div>


            <div
              class="mb-7"
              style="width: 49%; margin-left: 1%; height: 530px"
            >
              <card class="w-full h-full p-2">
                <div class="w-full h-full p-3 bg-ghostWhite rounded-sm">
                <div class="w-full mt-2 flex">
                  <div
                    class="flex flex-grow text-base ml-3 font-semibold"
                  >
                    <span class="text-base font-semibold">
                      Work Anniversaries
                    </span>
                    <div class="text-sm uppercase text-flame font-semibold flex flex-col justify-center mx-1">
                      ({{ anniversaryData.length }})
                    </div>
                  </div>
                  <div class="flex">
                    <span class="text-xs font-semibold mr-3 mt-1 uppercase">
                      {{ anniversaryTab }}
                    </span>
                    <span class="">
                      <Menu left top="-20" margin="14" class="p-0 mt">
                        <template v-slot:title>
                          <Icon
                            icon-name="icon-arrow-down"
                            style="color: #f15a29"
                          />
                        </template>
                        <div
                          style="width: 260px; height: 200px"
                          class="py-3 px-2"
                        >
                          <div
                            style="padding: 0px 10px 15px; height: 30px"
                            class="text-center"
                          >
                            <span class="text-sm text-romanSilver">
                              --Select an option--
                            </span>
                          </div>
                          <div class="border w-full mb-2" style="height: 0px" />
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              anniversaryTab === 'Today'
                                ? 'bg-flame text-white'
                                : ''
                            "
                            @click="setAnniversaryTab('Today')"
                          >
                            <p class="pt-1">Today</p>
                          </div>
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              anniversaryTab === 'This Week'
                                ? 'bg-flame text-white'
                                : ''
                            "
                            @click="setAnniversaryTab('This Week')"
                          >
                            <p class="pt-1">This Week</p>
                          </div>
                          <div
                            class="flex py-1 px-3 mb-3 more cursor-pointer"
                            :class="
                              anniversaryTab === 'This Month'
                                ? 'bg-flame text-white'
                                : ''
                            "
                            @click="setAnniversaryTab('This Month')"
                          >
                            <p class="pt-1">This Month</p>
                          </div>
                        </div>
                      </Menu>
                    </span>
                  </div>
                </div>
                <div style="height: 100%" v-if="anniversaryLoader">
                  <loader
                    size="xxs"
                    class="mt-10 mb-10"
                    :loader-image="false"
                  />
                </div>
                <div v-else>
                  <div
                    v-if="anniversaryData.length === 0"
                    class="flex flex-col text-center mb-6"
                  >
                    <Icon
                      icon-name="dashboard-error"
                      class="-mt-1 iconSize mx-24"
                      style="width: 350px; height: 350px"
                    />
                    <p class="text-xl font-bold">
                      No Data Available
                    </p>
                    <p class="text-xs text-romanSilver">
                      There is no data to show currently.
                    </p>
                  </div>
                  <WorkAnniversary :anniversary="anniversaryData" v-else />
                </div>
                </div>
              </card>
            </div>


            <div class="mb-7 w-full">
              <card class="w-full h-full p-2">
                <div class="w-full h-full p-3 bg-ghostWhite rounded-sm">
                <div class="w-full flex flex-col my-2">
                  <div
                    class="flex flex-grow text-base ml-3 font-semibold"
                  >
                    <span class="text-base font-semibold">
                      Leave of Abscence
                    </span>
                    <div class="text-sm uppercase text-flame font-semibold flex flex-col justify-center mx-1">
                      ({{ abscenceData.length }})
                    </div>
                  </div>

                  <CardFooter
                    @reload="reload()"
                    @actionModal="handleModal()"
                    @sortType="handleSort($event)"
                    @searchResult="handleSearch($event)"
                    class="mb-5 mt-2"
                  />
                  <div style="height: 100%" v-if="loadingTable">
                    <loader
                      size="xxs"
                      class="mt-10 mb-10"
                      :loader-image="false"
                    />
                  </div>
                  <div v-else>
                    <div
                      v-if="abscenceData.length === 0"
                      class="flex flex-col text-center mb-3"
                    >
                      <Icon
                        icon-name="dashboard-error"
                        class="-mt-1 iconSize"
                        style="width: 350px; height: 350px; margin: 0 auto"
                      />
                      <p class="text-xl font-bold">
                        No Data Available
                      </p>
                      <p class="text-xs text-romanSilver">
                        There is no data to show currently.
                      </p>
                    </div>
                    <div v-else>
                      <Table
                        :headers="abscenceHeader"
                        :items="abscenceData"
                        class="w-full my-2"
                        aria-label="abscence table"
                        :loading="loadingTable"
                      >
                        <template v-slot:item="{ item }">
                          <div
                            v-if="item.fname"
                            style="width: 230px"
                            class="pt-3 pb-3"
                          >
                            <div class="flex">
                              <img
                                v-if="item.data.photo"
                                class="mr-2"
                                alt="photo"
                                style="width: 35px; height: 35px"
                                :src="item.data.photo"
                              />
                              <div
                                style="height: 35px; width: 35px; border-radius: 5px;"
                                class="text-blueCrayola border text-center font-semibold pt-2 mr-2"
                                v-else
                              >
                                {{
                                  $getInitials(
                                    `${item.data.fname} ${item.data.lname}`
                                  )
                                }}
                              </div>
                              <div class="w-auto flex flex-col">
                                <p
                                  class="text-sm font-semibold text-darkPurple"
                                >
                                  {{ `${item.data.fname} ${item.data.lname}` }}
                                </p>
                                <p class="text-xs font-black text-blueCrayola">
                                  {{ `${item.data.designation}` }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div v-else-if="item.totalDays">
                            <p>{{ item.data.totalDays }} days</p>
                          </div>
                          <span v-else-if="item.startDate">
                            {{
                              `${$DATEFORMAT(
                                new Date(item.data.startDate),
                                "MMMM dd, yyyy"
                              )}`
                            }}
                          </span>
                          <span v-else-if="item.endDate">
                            {{
                              `${$DATEFORMAT(
                                new Date(item.data.endDate),
                                "MMMM dd, yyyy"
                              )}`
                            }}
                          </span>
                        </template>
                      </Table>
                    </div>
                  </div>
                </div>
                </div>
              </card>
            </div>


          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import Table from "@scelloo/cloudenly-ui/src/components/table";
import LineChart from "@/components/LineChart";
import { lazyLoadInternalComp } from "@/utilities/lazyLoadComponent";

export default {
  components: {
    Card: lazyLoadInternalComp('Card'),
    CardFooter: lazyLoadInternalComp('CardFooter'),
    Table,
    Menu: lazyLoadInternalComp('Menu'),
    BirthdayCard: lazyLoadInternalComp('BirthdayCard'),
    WorkAnniversary: lazyLoadInternalComp('WorkAnniversary'),
    ApproversCard: lazyLoadInternalComp('ApproversCard'),
    Tag: lazyLoadInternalComp('Tag'),
    ScrollContainer: lazyLoadInternalComp('ScrollContainer'),
    LineChart,
    ProfileCard: lazyLoadInternalComp('profileCard'),
  },

  data() {
    return {
      loading: true,
      requestTab: "All",
      loadingTable: true,
      movementTab: "Promotions",
      birthdayTab: "This Month",
      anniversaryTab: "This Month",
      birthdayLoader: true,
      anniversaryLoader: true,
      approvalLoader: true,
      movementLoader: true,
      approvalsData: [],
      requestProgress: "",
      filteredProgress: [],
      birthdayData: [],
      anniversaryData: [],
      setProgress: [],
      chartLabels: [],
      chartData: [],
      chartLegend: [],
      movementLabels: [],
      movementInfo: [],
      movementGrowth: "",
      currentMovement: "",
      maxMovement: "",
      minMovement: "",
      bgColor: "",
      approvalsLoader: true,
      approvalTotal: "",

      movementData: {
        labels: [],
        datasets: []
      },

      movementOptions: {},

      loadingTab: false,

      abscenceHeader: [
        { title: "Employee", value: "fname" },
        { title: "Leave Type", value: "leaveType" },
        { title: "Location", value: "location" },
        { title: "Function", value: "orgFunction" },
        { title: "Total Days", value: "totalDays" },
        { title: "Leave Date", value: "startDate" },
        { title: "Return Date", value: "endDate" }
      ],

      abscenceData: [],

      lineStyles: {
        height: `390px`,
        width: `100%`,
        position: "relative"
      },

      myStyles: {
        height: `360px`,
        width: `360px`,
        position: "relative"
      },
    };
  },

  methods: {
    setRequestTab(tab) {
      this.requestTab = tab;
      this.getAllRequests(tab);
    },
    setBirthdayTab(tab) {
      this.birthdayTab = tab;
      this.birthdayLoader = true;
      switch (tab) {
        case "Today":
          this.getBirthdays("day");
          break;
        case "This Week":
          this.getBirthdays("week");
          break;
        case "This Month":
          this.getBirthdays("month");
          break;
        default:
          break;
      }
    },

    setAnniversaryTab(tab) {
      this.anniversaryTab = tab;
      this.anniversaryLoader = true;
      switch (tab) {
        case "Today":
          this.getAnniversary("day");
          break;
        case "This Week":
          this.getAnniversary("week");
          break;
        case "This Month":
          this.getAnniversary("month");
          break;
        default:
          break;
      }
    },

    setMovementTab(tab) {
      this.movementTab = tab;
      this.movementLabels = [];
      this.movementInfo = [];
      this.movementLoader = true;
      switch (tab) {
        case "Promotions":
          this.getEmployeeMovement("promotion");
          break;
        case "Transfers":
          this.getEmployeeMovement("transfer");
          break;
        case "Disengagements":
          this.getEmployeeMovement("disengagement");
          break;
        default:
          break;
      }
    },

    getBirthdays(tab) {
      this.$_getEmployeeBirthdays(tab).then(result => {
        this.birthdayData = result.data.birthday;
        this.birthdayLoader = false;
      });
    },

    getAnniversary(tab) {
      this.$_getEmployeeWorkAnniversary(tab).then(result => {
        this.anniversaryData = result.data.employees;
        this.anniversaryLoader = false;
      });
    },

    getEmployeeMovement(tab) {
      this.$_employeeMovements(tab, this.$AuthUser.id).then(result => {
        let i;
        const currentMonth = this.$DATEFORMAT(new Date(), "MM") - 1;

        for (i = 0; i < result.data.data.length; i++) {
          this.movementLabels.push(result.data.data[i].month.toUpperCase());
          this.movementInfo.push(result.data.data[i].count);

          if (i === currentMonth) {
            this.currentMovement = result.data.data[i].count;
          }

          if (Math.ceil(Math.max(...this.movementInfo)) < 100) {
            this.maxMovement = 100;
          } else {
            this.maxMovement = Math.ceil(Math.max(...this.movementInfo));
          }

          this.minMovement = 0;
        }
        this.movementData = {
          labels: [...this.movementLabels],
          datasets: [
            {
              backgroundColor: "rgba(241, 90, 41, 0.08)",
              borderColor: "#321C3B",
              borderWidth: 1,
              data: [...this.movementInfo]
            }
          ]
        };

        this.movementOptions = {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false
                }
              }
            ],
            yAxes: [
              {
                display: true,
                ticks: {
                  beginAtZero: false,
                  steps: 1,
                  stepValue: 10,
                  max: this.maxMovement,
                  min: this.minMovement
                },
                gridLines: {
                  display: true,
                  borderDash: [8, 4]
                }
              }
            ]
          }
        };
        this.movementLoader = false;
      });

      this.$_employeeMovementsGrowth(tab, this.$AuthUser.id).then(result => {
        this.movementGrowth = result.data.growth;
      });
    },

    getAllRequests(tab) {
      const userId = this.$AuthUser.id;
      const params = "";
      this.approvalLoader = true;
      this.approvalsData = [];

      this.$_getMyApprovalsfilter(params, userId)
        .then(result => {
          this.approvalLoader = false;
          const inProgressData = result.data.approvals;

          if (tab === "All") {
            const allApprovalsData = inProgressData.map(v => ({
              id: v.id,
              photo: v.photo,
              fname: v.fname,
              lname: v.lname,
              currentApprover: v.currentApprover,
              designation: v.userDesignation.name,
              dateSubmitted: v.dateSubmitted,
              requestName: v.requestName,
              requestSlug: v.requests.requestSlug,
              requestId: v.requestId
            }));

            allApprovalsData.filter(a => {
              if (a.currentApprover !== "hasApproved") {
                this.approvalsData.push(a);
              }
              return {};
            });
          } else if (tab === "Pending") {
            const pendingData = inProgressData.map(v => ({
              id: v.id,
              photo: v.photo,
              fname: v.fname,
              lname: v.lname,
              currentApprover: v.currentApprover,
              designation: v.userDesignation.name,
              dateSubmitted: v.dateSubmitted,
              requestName: v.requestName,
              requestSlug: v.requests.requestSlug,
              requestId: v.requestId
            }));

            pendingData.filter(a => {
              if (a.currentApprover === "inactive") {
                this.approvalsData.push(a);
              }
              return {};
            });
          } else if (tab === "Active") {
            const activeData = inProgressData.map(v => ({
              id: v.id,
              photo: v.photo,
              fname: v.fname,
              lname: v.lname,
              currentApprover: v.currentApprover,
              designation: v.userDesignation.name,
              dateSubmitted: v.dateSubmitted,
              requestName: v.requestName,
              requestSlug: v.requests.requestSlug,
              requestId: v.requestId
            }));

            activeData.filter(a => {
              if (a.currentApprover === "active") {
                this.approvalsData.push(a);
              }
              return {};
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },

    viewDetailedApproval(requestId, requestSlug) {
      this.$router.push({
        name: `Ess${requestSlug}`,
        params: { id: `${requestId}` }
      });
    },

    approveDetails(value) {
      if (value === "active") {
        return "border-color: #13B56A;";
      }
      if (value === "inactive") {
        return "border-color: #E99323;";
      }
      return "";
    },
    approveText(value) {
      if (value === "active") {
        return "Active";
      }
      if (value === "inactive") {
        return "Pending";
      }
      return "";
    },
    approveBgColor(value) {
      if (value === "active") {
        return "text-mediumSeaGreen bg-light-sea-green";
      }
      if (value === "inactive") {
        return "text-carrotOrange bgCarrot";
      }
      return "";
    },

    getLeaveAbscence() {
      this.$_getLeaveAbsence(this.$orgId).then(result => {
        this.loadingTable = false;
        const leaveAbscence = result.data.employees;
        this.abscenceData = leaveAbscence.map(v => ({
          photo: v.employee.photo,
          fname: v.employee.fname,
          lname: v.employee.lname,
          designation: v.employee.designation,
          leaveType: v.leaveType,
          location: v.location,
          orgFunction: v.orgFunction,
          totalDays: v.totalDays,
          startDate: v.startDate,
          endDate: v.endDate
        }));
      });
    },

    
    processMethodGroups(methods, groupSize) {
      for (let i = 0; i < methods.length; i += groupSize) {

        this.$nextTick(() => {
          const groupPromises = methods.slice(i, i + groupSize).map(method => {
            return new Promise((resolve, reject) => {
              try {
                // Checked if method is actually a function before calling it
                if (typeof method === 'function') {
                  const result = method();  // Executed the function
                  if (result && typeof result.then === 'function') {
                    result.then(resolve).catch(reject);
                  } else {
                    resolve(result);  // Resolve synchronous result
                  }
                } else {
                  throw new TypeError("Provided element is not a function");
                }
              } catch (error) {
                reject(error); 
              }
            });
          });

          Promise.all(groupPromises).catch(e => console.error(e));
        });
      }
    }
  },

  async mounted() {
    // Executed the first two critical function
    this.getAllRequests("All");
    this.getEmployeeMovement("promotion");
    this.loading = false;

    // Used Vue.nextTick to defer the execution of the next tasks
    this.$nextTick(() => {
    this.processMethodGroups([
      this.getBirthdays.bind(this, "month")
,     this.getAnniversary.bind(this, "month"),
      this.getLeaveAbscence.bind(this)
    ], 2);
    });
  },
};
</script>

<style scoped>
.bgCarrot {
  background-color: rgba(233, 147, 35, 0.08);
}
.mt {
  margin-top: 0.37rem;
}
</style>
