<template>
  <div class="w-full h-full">
    <div style="height:100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else>
      <manager-dashboard v-if="manager" />
      <employee-dashboard v-else />
    </div>
  </div>
</template>

<script>
import ManagerDashboard from "./ManagerDashboard";
import EmployeeDashboard from "./EmployeeDashboard";

export default {
  components: {
    ManagerDashboard,
    EmployeeDashboard
  },

  data() {
    return {
      manager: false,
      loading: true,
      employeeDetails: {}
    };
  },

  methods: {
    getUser() {
      this.$_getUser(this.$AuthUser.id).then(result => {
        this.loading = false;
        if (result.data.user.userDesignation.reportingTo !== null) {
          this.manager = false;
        } else {
          this.manager = true;
        }
      });
    },
    getEmploymentInfo() {
      this.$store.commit("SET_LOADING", true);
      this.$_getOneEmployeeAll({
        id: this.$AuthUser.id
      })
        .then(({ data }) => {
          this.$store.commit("SET_EMPLOYEE", data);
          this.$store.commit("SET_LOADING", false);
        })
        .catch(err => {
          this.$store.commit("SET_LOADING", false);
          throw new Error(err);
        });
    }
  },

  mounted() {
    this.getUser();
    this.getEmploymentInfo();
  }
};
</script>
